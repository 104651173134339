<template>
  <v-dialog
    v-model="dialogAddNotaCreditoManual"
    @click:outside="$emit('update:dialogAddNotaCreditoManual', false)"
    @keydown.esc="$emit('update:dialogAddNotaCreditoManual', false)"
    max-width="1000px"
    scrollable
  >
    <v-card tile :loading="loading" :disabled="loading">
      <v-card-title class="subtitle-1 tocs_gray_1 px-4 py-3">
        <span class="headline">
          {{ $tc("global.add") }} {{ $tc("global.notaCreditoManual") }}
        </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          dark
          @click="$emit('update:dialogAddNotaCreditoManual', false)"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pa-5">
        <v-select
          v-model="timbrado_id"
          :items="timbrados"
          item-text="timbrado"
          item-value="timbrado_id"
          clearable
          outlined
          dense
          hide-details
          :label="$tc('global.timbrado')"
        >
          <template v-slot:selection="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
          <template v-slot:item="{ item }">
            <span>
              {{
                `${item.descricao ? item.descricao : ""} - ${item.timbrado} `
              }}
            </span>
          </template>
        </v-select>

        <div class="py-4">
          <SearchClientes :cliente_sync.sync="selectedCliente" class="py-4" />

          <v-row>
            <v-col cols="12" md="4">
              <v-select
                v-model="selectedMoeda"
                :items="moedas"
                dense
                item-text="sigla"
                item-value="value"
                return-object
                outlined
                :label="$tc('global.moeda')"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                ref="valorDesconto"
                v-model="valorDesconto"
                :label="$tc('global.desconto')"
                dense
                required
                :rules="formRules"
                :prefix="selectedMoeda ? selectedMoeda.sigla : ''"
                outlined
                height="40px"
                :error-messages="erroDesconto"
                :disabled="!selectedMoeda"
                style="display: flex"
                class="remove-underline input-font-size input-right"
                v-currency="vCurrencyOptions"
                @blur="validaDesconto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="fatura.comprovante_venda"
                :label="$tc('global.comprovante') + ' ' + $tc('global.venda')"
                dense
                required
                :rules="formRules"
                outlined
                class="remove-underline input-font-size input-right"
              ></v-text-field>
            </v-col>
          </v-row>
        </div>

        <v-card flat class="px-4" outlined>
          <v-card-title>
            {{ $tc("global.add") + " Itens" }}
          </v-card-title>
          <v-card-text class="px-4">
            <v-form
              @submit.prevent
              ref="form"
              v-model="validForm"
              lazy-validation
            >
              <v-row dense>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="fatura_item.descricao"
                    :label="$tc('global.descricao')"
                    dense
                    outlined
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="fatura_item.tipo_imposto"
                    :items="tipo_imposto"
                    dense
                    item-text="descricao"
                    item-value="value"
                    outlined
                    :label="$tc('global.tipo') + ' ' + $tc('global.imposto')"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="itemQtde"
                    hide-details
                    dense
                    flat
                    outlined
                    label="Qtde"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="itemQtde++"
                    prepend-icon="mdi-minus"
                    @click:prepend="itemQtde > 1 ? itemQtde-- : ''"
                    class="ml-3 input-center"
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    ref="valor"
                    v-model="valor"
                    :label="$tc('global.valor')"
                    :disabled="!selectedMoeda ? true : false"
                    :rules="formRules"
                    v-currency="vCurrencyOptions"
                    hide-details
                    dense
                    outlined
                    @keydown.enter="addFaturaItem"
                    @blur="setTotalItem"
                  >
                    <template v-if="valor > 0" v-slot:append>
                      <v-chip small v-if="selectedMoeda.sigla === 'G$'">
                        {{ selectedMoeda.sigla }}
                        {{ valorTotalItem | guarani }}
                      </v-chip>
                      <v-chip small v-else>
                        {{ selectedMoeda.sigla }}
                        {{ valorTotalItem | currency }}
                      </v-chip>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="px-0">
            <v-spacer></v-spacer>

            <v-btn
              color="button_1"
              class="white--text"
              depressed
              small
              :disabled="disableAddItem"
              @click="addFaturaItem"
            >
              {{ $tc("global.add") }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card outlined class="mt-4">
          <v-data-table
            :headers="headers"
            :items="fatura_items"
            :loading="loading"
            class="data-tables"
            hide-default-footer
          >
            <template v-slot:item.valor="{ item }">
              <span v-if="selectedMoeda.sigla === 'G$'">
                {{ item.valor | guarani }}
              </span>
              <span v-else> {{ item.valor | currency }} </span>
            </template>
            <template v-slot:item.total="{ item }">
              <span v-if="selectedMoeda.sigla === 'G$'">
                {{ item.total | guarani }}
              </span>
              <span v-else> {{ item.total | currency }} </span>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn icon small @click.stop="removerItem(item)" class="mr-4">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
        <div class="d-flex aling-center justify-space-between py-4">
          <span>
            Sub-Total : {{ selectedMoeda ? selectedMoeda.sigla : "" }}
            <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
              {{ subTotalFatura | guarani }}
            </span>
            <span v-else>
              {{ subTotalFatura | currency }}
            </span>
          </span>
          <span>
            Total : {{ selectedMoeda ? selectedMoeda.sigla : "" }}
            <span v-if="selectedMoeda && selectedMoeda.sigla === 'G$'">
              {{ totalFatura | guarani }}
            </span>
            <span v-else>
              {{ totalFatura | currency }}
            </span>
          </span>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click.prevent="notaCreditoManual"
          class="white--text"
          color="button_2"
          small
          :disabled="disableGerarNota"
          :class="$vuetify.breakpoint.mdAndUp ? '' : 'mr-12'"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getVendas } from "@/api/vendas/vendas.js";
import { postNotaCreditoManual } from "@/api/faturas/faturas.js";
import { fetchUserTimbrados } from "@/api/usuarios/users_timbrado.js";
import { inputRequired } from "@/helpers/utils.js";
import { mapState } from "vuex";

export default {
  name: "DialogAddNotaCreditoManual",

  props: {
    dialogAddNotaCreditoManual: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    SearchClientes: () => import("@/components/fields/SearchClientes.vue"),
  },

  data() {
    return {
      loading: false,
      timbrados: [],
      timbrado_id: null,
      selectedCliente: null,
      validForm: true,
      formRules: [inputRequired],
      valor: 0,
      fatura_item: {
        tipo_imposto: "GRAVADO_10",
      },
      fatura_items: [],
      itemQtde: 1,
      erroDesconto: "",
      tipo_imposto: [
        {
          descricao: "ISENTO",
          value: "ISENTO",
        },
        {
          descricao: "GRAVADO 10 %",
          value: "GRAVADO_10",
        },
        {
          descricao: "GRAVADO 5 %",
          value: "GRAVADO_5",
        },
        {
          descricao: "DECRETO",
          value: "DECRETO",
        },
      ],
      valorDesconto: 0,
      selectedMoeda: null,
      fatura: {},
      valorTotalItem: 0,
      invalidDesconto: false,
    };
  },

  watch: {},

  computed: {
    headers() {
      return [
        {
          text: this.$tc("global.descricao"),
          class: "tocs_gray_1",
          align: "start",
          value: "descricao",

          sortable: false,
        },
        {
          text: this.$tc("global.imposto"),
          class: "tocs_gray_1",
          align: "start",
          value: "iva_porcentagem",

          sortable: false,
        },
        {
          text: this.$tc("global.qtd"),
          class: "tocs_gray_1",
          value: "qtde",
          sortable: false,
        },
        {
          text: this.$tc("global.valor"),
          class: "tocs_gray_1",
          value: "valor",
          sortable: false,
        },
        {
          text: this.$tc("global.total"),
          class: "tocs_gray_1",
          value: "total",
          sortable: false,
        },
        {
          text: "",
          class: "tocs_gray_1",
          value: "actions",
          sortable: false,
        },
      ];
    },
    vCurrencyOptions() {
      return {
        precision: this.selectedMoeda?.sigla === "G$" ? 0 : undefined,
        distractionFree: false,
      };
    },
    ...mapState("Moedas", {
      moedas: (state) => state.moedas,
    }),

    disableAddItem() {
      let result = false;

      if (
        !this.fatura_item.descricao ||
        this.$ci.parse(this.valor, this.vCurrencyOptions) < 0 ||
        Number(this.itemQtde) < 1
      ) {
        result = true;
      }

      return result;
    },

    disableGerarNota() {
      let result = false;

      if (
        !this.selectedMoeda ||
        !this.selectedCliente ||
        !this.timbrado_id ||
        this.fatura_items.length < 1 ||
        this.invalidDesconto
      ) {
        result = true;
      }

      return result;
    },

    subTotalFatura() {
      let result = 0;

      if (this.fatura_items.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.valor * currentValue.qtde;
        };

        result = this.fatura_items.reduce(reducer, 0);
      }
      return result;
    },

    totalFatura() {
      let result = 0;

      if (this.fatura_items.length) {
        const reducer = (accumulator, currentValue) => {
          return accumulator + currentValue.valor * currentValue.qtde;
        };

        result =
          this.fatura_items.reduce(reducer, 0) -
          this.$ci.parse(this.valorDesconto, this.vCurrencyOptions);
      }
      return result;
    },
  },

  methods: {
    setTotalItem() {
      if (this.valor > 0 && this.itemQtde > 0) {
        this.valorTotalItem =
          this.$ci.parse(this.valor, this.vCurrencyOptions) * this.itemQtde;
      }
    },

    async fetchVendas() {
      const response = await getVendas(`?status=5&sem_fatura=true`);
      this.vendas = response;
    },
    async getUserTimbrados() {
      const response = await fetchUserTimbrados("?tipo=2");
      this.timbrados = response;
    },

    notaCreditoManual() {
      this.loading = true;
      let fatura = {};
      let valores = {};
      valores.desconto = this.$ci.parse(
        this.valorDesconto,
        this.vCurrencyOptions
      );
      valores.subTotalFatura = this.subTotalFatura;
      valores.totalFatura = this.totalFatura;

      fatura.timbrado_id = this.timbrado_id;
      fatura.comprovante_venda = this.fatura.comprovante_venda;
      fatura.cliente_id = this.selectedCliente.id;
      fatura.moeda_id = this.selectedMoeda.id_moeda;
      fatura.fatura_items = this.fatura_items;
      fatura.valores = valores;

      postNotaCreditoManual(fatura)
        .then((response) => {
          if (response.status === 201) {
            this.fetchVendas();
            this.$emit("fetch-faturas");
            this.$emit("update:dialogAddNotaCreditoManual", false);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addFaturaItem() {
      let item = {};

      item.descricao = this.fatura_item.descricao;
      item.qtde = this.itemQtde;
      item.valor = this.$ci.parse(this.valor, this.vCurrencyOptions);
      item.total = item.qtde * item.valor;
      if (
        this.fatura_item.tipo_imposto == "GRAVADO_10" ||
        this.fatura_item.tipo_imposto == "DECRETO"
      ) {
        item.iva_porcentagem = 10;
      } else if (this.fatura_item.tipo_imposto === "GRAVADO_5") {
        item.iva_porcentagem = 5;
      } else {
        item.iva_porcentagem = 0;
      }
      this.fatura_items.push(item);
      this.validaDesconto();
      this.fatura_item.descricao = "";
      this.fatura_item.tipo_imposto = this.tipo_imposto.find(
        (imposto) => imposto.value == "GRAVADO_10"
      ).value;
      this.itemQtde = 1;
      this.$ci.setValue(this.$refs.valor, 0);
    },

    validaDesconto() {
      let desconto = this.$ci.parse(this.valorDesconto, this.vCurrencyOptions);

      if (desconto === null || desconto === undefined || desconto === "") {
        this.invalidDesconto = true;
      } else {
        if (desconto < 0 || desconto > this.subTotalFatura || isNaN(desconto)) {
          this.erroDesconto = "Valor de desconto inválido";
          this.invalidDesconto = true;
        } else {
          this.erroDesconto = "";

          this.invalidDesconto = false;
        }
      }
    },

    removerItem(item) {
      this.fatura_items.splice(this.fatura_items.indexOf(item), 1);
    },
  },

  async mounted() {
    try {
      this.loading = true;
      this.fatura_item.tipo_imposto = this.tipo_imposto.find(
        (imposto) => imposto.value == "GRAVADO_10"
      ).value;
      this.selectedMoeda = this.moedas.find((moeda) => {
        return moeda.sigla === "G$";
      });
      await this.getUserTimbrados();
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  },
};
</script>

<style></style>
